// Fonts
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');

.text-optum-color-dark {
  color: #030d68;
}

.text-optum-color-orange {
color: #ff612b;
}

.ng-select {
    .ng-select-container {
      min-height: 34px;
      height: 34px !important;
      border: 1px solid #d2d6de;
      border-radius: 3px;
    }
    .ng-select-container .ng-value-container .ng-placeholder {
      color: #666;
    }
  }
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-left: 16px;
  }
  .ng-select .ng-select-container .ng-value-container {
    padding-left: 16px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    padding: 8px 16px;
  }

  .main-header .logo {
    padding: 0;
  }

  /* width */
//   ::-webkit-scrollbar {
//     width: 5px;
//   }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  * {
    outline: none !important;
  }

  body {
    background: #d2d6de;
    //background-color: #fff !important;
  }

  // .skin-yellow .main-header .navbar {
  //   background-color: #ff612b;
  // }

  // .skin-yellow .main-header .logo {
  //   background-color: #ff612b;
  // }

  // .skin-yellow .main-header li.user-header {
  //   background-color: #ff612b;
  // }

  // .skin-yellow .sidebar-menu > li.active > a {
  //   border-left-color: #ff612b;
  // }

  .btn-secondary {
    background-color: #fff !important;
    color: #2164cf !important;
    border: 1px solid #2164cf !important;
  }

  .ng-tooltip {
    position: absolute;
    max-width: 200px;
    font-size: 14px;
    text-align: center;
    color: #f8f8f2;
    padding: 3px 8px;
    background: #282a36;
    border-radius: 4px;
    z-index: 2000;
    opacity: 0;
  }
  .ng-tooltip:after {
    content: '';
    position: absolute;
    border-style: solid;
  }
  .ng-tooltip-top:after {
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: black transparent transparent transparent;
  }
  .ng-tooltip-bottom:after {
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: transparent transparent black transparent;
  }
  .ng-tooltip-left:after {
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: transparent transparent transparent black;
  }
  .ng-tooltip-right:after {
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: transparent black transparent transparent;
  }
  .ng-tooltip-show {
    opacity: 1;
  }

  .snackbar {
    visibility: hidden;
    min-width: 250px;
    background-color: #333;
    color: #fff;
    text-align: left;
    border-radius: 2px;
    padding: 10px;
    position: fixed;
    z-index: 1000;
    right: 30px;
    bottom: 10px;
    box-shadow: 3px 3px 3px #898978;
  }

  .snakbar-message {
    line-height: 30px;
    margin-right: 10px;
  }

  .show-bar {
    visibility: visible !important;
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }

  @keyframes fadeout {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }

  .snakbar-icon {
    transform: scale(2);
    padding: 10px 10px 8px 8px;
  }

  .main-sidebar {
    z-index: 800;
  }

  .main-header .logo .logo-lg {
    display: inline-block;
    width: 230px;
  }

  .btn-primary.active.focus,
  .btn-primary.active:focus,
  .btn-primary.active:hover,
  .btn-primary:active.focus,
  .btn-primary:active:focus,
  .btn-primary:active:hover,
  .open > .dropdown-toggle.btn-primary.focus,
  .open > .dropdown-toggle.btn-primary:focus,
  .open > .dropdown-toggle.btn-primary:hover {
    color: #fff;
    background-color: rgb(49, 107, 190);
    border-color: rgb(49, 107, 198);
  }

  .pre {
    display: block;
    padding: 6.5px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .main-header .sidebar-toggle {
    color: #ff612b;
    &:hover {
      color: #ff612b;
    }
  }

  .fa {
    &.fa-home {
      color: #ff612b;
    }
  }

  .btn-primary {
//    background-color: rgb(49, 107, 189);
    border-color: rgb(11, 67, 144);
    color: #fff;
    background-color: #030d68;
    border-radius: 25px;
  }

  // .btn-default,
  // .btn-primary {
  //   border-radius: 20px;
  // }

  .form-control {
    border-radius: 3px;
  }

  .btn-primary:hover,
  .btn-primary:active,
  .btn-primary.hover {
    background-color: rgb(33, 100, 207);
  }

  .d-flex {
    display: flex;
  }

  .align-items-center {
    align-items: center;
  }

  .justify-content-space-between {
    justify-content: space-between;
  }

  .tab-buttons .btn-warning {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #ff612b;
    color: #222;
    font-weight: bold;
  }

  .tab-buttons .btn-warning:active,
  .tab-buttons .btn-primary:active {
    box-shadow: none;
    background-color: transparent;
    border-color: transparent;
    color: #222;
    font-weight: bold;
    border-bottom: 2px solid #ff612b;
  }

  .tab-buttons .btn-primary {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #e0e0e0;
    color: #222;
  }

  // .box-warning {
  //   border-top: transparent !important;
  // }

  .content-header {
    background-color: #fff !important;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%) !important;
    padding: 10px 15px 10px 15px !important;
  }

  .content-header > h1 {
    margin: 5px;
    font-size: 18px;
  }

  a,
  a:hover,
  a:active {
    color: #316bbe;
  }

  .pagination > .active > a,
  .pagination > .active > a:focus,
  .pagination > .active > a:hover,
  .pagination > .active > span,
  .pagination > .active > span:focus,
  .pagination > .active > span:hover {
    background-color: #316bbe;
  }

  .products-list .product-description {
    color: #222;
  }

  .progress-group .progress-text {
    font-weight: normal;
  }

  .products-list .product-img .btn-app {
    padding: 5px 5px !important;
    height: 40px !important;
    width: 40px !important;
  }

  .products-list .product-img .btn-app .fa {
    font-size: 15px;
  }

  input[title='Search'] {
    border-radius: 5px;
  }

  button[title='Clear'] {
    margin-left: -2px !important;
  }

  .btn-round {
    padding: 0 !important;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    background-color: #fff;
    color: #ff612b;
  }

  .dropdown-menu > li > a {
    padding: 8px 20px !important;
    cursor: pointer !important;
    &:hover {
      background-color: #eee;
    }
  }

  .btn-primary.focus,
  .btn-primary:focus {
    color: #fff;
    background-color: #316bbe;
    border-color: rgb(23, 58, 86);
  }

  .btn-delete {
    background-color: #e08887;
  }

  .text-white {
    color: #fff;
  }

  // .cdo-group-list .box-body {
  //   padding: 10px 0px 10px 10px;
  // }

  .radio-group {
    margin-bottom: 15px;
    .radio-item {
      display: inline-block;
      position: relative;
      padding: 0 0px;
      margin: 10px 0 0;
    }

    .radio-item input[type='radio'] {
      display: none;
    }

    .radio-item label {
      font-weight: normal;
      cursor: pointer;
    }

    .radio-item label:before {
      content: ' ';
      display: inline-block;
      position: relative;
      top: 5px;
      margin: 0 5px 0 0;
      width: 20px;
      height: 20px;
      border-radius: 11px;
      border: 2px solid #3c8dbc;
      background-color: transparent;
    }

    .radio-item input[type='radio']:checked + label:after {
      border-radius: 11px;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 9px;
      left: 4px;
      content: ' ';
      display: block;
      background: #3c8dbc;
    }

    .help-block {
      color: #dd4b39 !important;
    }
  }

  .divider {
    border-top: 1px solid #ccc;
    margin-bottom: 10px;
  }

  .label-deleted {
    background-color: #fa6c68;
    color: #fff;
  }

  .btn-green {
    background-color: #33c390;
    color: #fff;
  }

  .label-active {
    background-color: #33c390;
    color: #fff;
  }

  .group-active {
    background-color: #434446;
    // margin-right: 10px;
    border-radius: 3px;
    .product-title {
      color: #fff;
    }
  }

  .group-locked {
    background-color: #dddddda1;
    // margin-right: 10px;
    border-radius: 3px;
    cursor: pointer;
    .product-title {
      color: #316bbe;
    }
  }

  li.group-active:after {
    content: ' ';
    height: 0;
    position: absolute;
    right: 0px;
    color: #fff;
    border: 6px solid transparent;
    display: inline;
    border-left-color: #434446;
    margin-top: 5px;
  }

  .label-custom {
    padding: 0.2em 0.6em 0.3em 0.6em;
  }

  .label-custom-2 {
    padding: 0.2em 0.6em 0.3em 0.6em;
  }

  .text-white {
    color: #fff;
  }

  .text-black {
    color: #797272 !important;
  }

  .btn-secondary {
    background-color: #fff;
    color: #217cdd;
    border-color: #0b4390;
    border-radius: 25px;
  }

  .btn-secondary:disabled {
    color: #222;
    border-color: #eee;
  }

  .align-center {
    display: flex;
    justify-content: center;
  }

  /*========================
    New styles
  ==========================*/
  /* Utilities */
  .box.box-default {
    // border-top-color: #fff;
    border-top-color: #ff612b !important;

  }
  .justify-content-center {
    justify-content: center;
  }
  .optum-orange {
    color: #ff612b !important;
  }
  .optum-border-top {
    border-top: 2px solid #ff612b !important;
  }
  .optum-border-bottom {
    border-bottom: 2px solid #ff612b !important;
  }
  /*Header section*/
  .main-header {
    background-color: #fbf9f4;
    // box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1);
    box-shadow: 5px 10px 8px rgba(0,0,0,.125);
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  .logo-section {
    display: flex;
    align-items: start;
    justify-content: start;
    // padding-top: 25px;
  }

  .login-logo-section {
    display: flex;
    align-items: start;
    justify-content: start;
    padding-top: 25px;
  }
  .optum-logo {
    border-right: 1px solid #e0e0e0;
    padding: 0px 15px 0px 15px;
  }

  .logo-section .PA-txt {
    font-size: 22px;
    margin-left: 15px;
    margin-top: -4px;
    // padding: 0px 5px 0px 15px;
    color: #222222;
  }
  .wrapper {
    background-color: #ecf0f5 !important;
  }
  .main-header .user-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 190px;
    margin-top: -4px;
  }
  .main-header .user-section .user {
    color: #636363;
    padding-right: 10px;
  }
  .main-header .user-section .px-2 {
    padding-right: 5px;
  }
  .main-header .user-section .signout {
    color: #316bbe;
    // border-left: 1px solid #636363;
    padding-left: 10px;
    line-height: 10px;
  }

 .borderstyle {
    // border-bottom: solid;
    // border-color: rgba(0,0,0,.125);
    // border-width: thin;
    // border-radius: 0.25rem !important;

    border-bottom: 1px solid #dee2e6!important;
    padding: 15px;
  }

  .textCenter {
    text-align: center !important;
  }
  /*Sidebar toglle*/
  .sidebar-toggle {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    margin: 0px 20px;
    padding: 5px !important;
  }
  .sidebar-toggle::before {
    display: none;
  }
  .sidebar-toggle .icon-bar {
    display: block !important;
    width: 100% !important;
    height: 1px;
    margin-bottom: 5px;
    background-color: #636363;
  }
  .sidebar-toggle .icon-bar:nth-child(3) {
    width: 80% !important;
  }

  /* Login screeen */
  .login-container {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
  }
  .login-box {
    margin: 0px auto;
  }
  .login-box-content {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .login-box-content-center {
    justify-content: center !important;
  }
  .login-box .box-content-wrapper .content-inner {
    display: flex;
    align-items: center;
    justify-content: center !important;
    // padding: 25px 25px;
  }

  .content-wrapper{
    margin-left: 10% !important;
    padding-top: 85px !important;
  }

  .login-box .box-body {
    padding: 0px;
  }

 .login-box .login-box-body {
    padding: 30px 10px ;
    width:100%
  }
  .login-box .login-box-msg {
    padding: 0px !important;
    text-align: center !important;
    margin-bottom: 10px !important;
  }
  .login-box h2 {
    font-size: 16px;
    color: #222;
    text-align: center;
    line-height: 24px;
    margin: 0px 0px 20px 0px;
  }
  .login-box-content-bgcolor{
background-color: #E8F0FE;
  }
  .login-box .footer {
    color: #636363;
    font-size: 12px;
    padding-bottom: 25px;
  }
  .login-box .box.box-primary {
    border-top-color: #ff612b !important;
  }
  .login-box .btn-primary {
    display: inline;
    width: auto;
    border-radius: 20px;
    padding: 10px 40px;
    background-color: #316bbe;
  }

  .btn-text-color{
    color: #316bbe !important;
    cursor: pointer;
    padding-right: 20px;
    // font-weight: bold;
  }
  .user-panel > .info > p {
    font-weight: 600;
    margin-bottom: 0px !important;
  }

  .skin-yellow .sidebar-menu > li.active > a {
    border-left-color: #ff851b;
  }

  .headerTitle {
    border-bottom: 2px solid #ff612b;
    padding-bottom: 15px;
    margin-left: 20px;
  }

  label {
    font-weight: 600 !important;
  }

  .table th {
    font-weight: 600 !important;    
    padding-top: 7px !important;
    padding-bottom: 7px !important;    
  }

  .table td {
    padding:7px 7px 7px 7px  !important;
  }

  .alert-success {
    color: #155724 !important;
    background-color: #d4edda !important;
    border-color: #c3e6cb !important;
}

.alert-danger {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
}

.alert-warning a:hover {
  color: #2c2101 !important;
}

.alert-warning {
    color: #856404 !important;
    background-color: #fff3cd !important;
    border-color: #ffeeba !important; 
}

.btn-tab{
  border-radius: 25px;
    padding: 15px 60px;
    width: auto !important;
    font-size: 18px;
    font-weight: bold;
}

.help-block{
  color : #dd4b39 !important;
}

.main-footerUser {
  margin-left: 10%;
  margin-right: 10%;
  background: #fff;
  padding: 15px;
  color: #444;
  border-top: 1px solid #d2d6de;
}

.logo-content
{
  margin-left: 200px;
}

.profile-icon{
  display: flex;
  justify-content: center;
}

.circle {
  background: #daf6fa;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: inline-block;
  margin: 4px 0 0 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btncircle {
  background: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: inline-block !important;
  margin: 5px 5px 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2164cf !important;
}

.card-body{
  padding: 15px !important;
}

.card{
  padding: 10px 0px;
}

.card-title{
  margin-bottom: 0px;
}

.btn-primary.disabled, .btn-primary:disabled{
  opacity: 0.5;
}

@media (min-width:990px){
  .table-responsive{
    overflow-x: hidden;
  }
}

.labelHeader {
  color: #030d68 !important;
  font-size: 16px;
  margin-bottom: 11px;
  display: block;
  font-weight: bold;
}

/*Data table CSS*/

.ngx-datatable.bootstrap {
  background-color: transparent;
  box-shadow: 0 0 0 #000;
}

.ngx-datatable.scroll-vertical {
  height: 300px;
}

.no-detail-row .datatable-row-detail {
  display: none !important;
}

.ngx-datatable.bootstrap.single-selection .datatable-body-row.active,
.ngx-datatable.bootstrap.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.bootstrap.multi-selection .datatable-body-row.active,
.ngx-datatable.bootstrap.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.bootstrap.multi-click-selection .datatable-body-row.active,
.ngx-datatable.bootstrap.multi-click-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.bootstrap.single-selection .datatable-body-row.active:hover,
.ngx-datatable.bootstrap.single-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.bootstrap.multi-selection .datatable-body-row.active:hover,
.ngx-datatable.bootstrap.multi-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.bootstrap.multi-click-selection .datatable-body-row.active:hover,
.ngx-datatable.bootstrap.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: red !important;
}

.datatable-body-cell {
  color: #333;;
  font-size: 1rem !important;
}

.datatable-icon-right {
  text-decoration: none !important;
}

.ngx-datatable.bootstrap .empty-row {
  text-align: center;
  margin-top: 20px;
}

.ngx-datatable.bootstrap .datatable-footer {
  background-color: #fff;
  color: #333;
  width:100% !important;
  font-size: 1rem !important;
}

.ngx-datatable.bootstrap .datatable-header {
  background-color: #fff;
  // width:100% !important;
  border-bottom: 2px solid #ff612b;
  border-top: 1px solid #ced4da;
  font-size: 1rem !important;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  color: #333;
}

.pager li>a,
.pager li>span {
  border-color: black;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: #436fa2;
  border-color: #ddd;
  color: #fff;
}

.ngx-datatable.bootstrap .datatable-header .sort-active
{
  background-color: #daf6fa;;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  border: 1px solid #f4f4f4;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: #f1f1f1
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-odd {
  background-color: #fff;
}
.ngx-datatable.bootstrap .datatable-body .progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
}

.ngx-datatable.bootstrap .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  background-color: #aad1f9;
}

.ngx-datatable.bootstrap .datatable-body .progress-linear .container .bar {
  transition: all .2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform .2s linear;
  transition: transform .2s linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: auto;
  height: 5px;
}

.wrapper .aside-container .aside-inner,
.wrapper .aside-container {
  width: 230px;
}

.ngx-datatable .datatable-row-group:first-child
{
  padding-left: 10px !important;
}

.ngx-datatable .datatable-row-group:last-child
{
  padding-right: 10px !important;
}


.underline {
  margin: 0px;
  border-bottom: 1px solid lightgray ;
  padding-bottom: 10px;
  padding-top: 10px;

  .col {
      padding-left: 5px;
  }
}

.content-wrapper{
  position: static !important;
}

.modal{
  padding-top: 75px;
}

 .pdi-grid .datatable-body .datatable-body-row .datatable-body-cell{
  padding: .75rem 0rem !important;
 }

 /*Additional CSS for resolution problems*/

.logo-content
{
  margin-left: 0px;
}

.main-header .user-section{
  margin-right: 0px;
}

.main-header .user-section{
  justify-content: end;
}

.optum-logo {
  padding: 0px 15px 0px 0px;
}

.content-wrapper{
  margin-left: 0px !important;
}

.container{
  max-width: 1320px;
}

.content-wrapper{
  padding-top: 50px !important;
}

.main-footerUser{
  margin-left: 0px;
  margin-right: 0px;
}

.main-footerUser{
  text-align: center;
  font-size: 13px;
}

.content{
  padding: 0px;
}

.logo-section{
  align-items: center;
}

.py-0{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.btn-tab {
  padding: 10px 40px;
}

@media (max-width:1320px){
  body, .datatable-body-cell, .ngx-datatable.bootstrap .datatable-header{
    font-size: 14px !important;
  }
  .logo-section .PA-txt{
    margin-top: 0px;
    font-size: 18px;
  }
  .container{
    max-width: 1140px;
  }
}




/* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';
